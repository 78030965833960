import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Widget, { Font, Color, FontSize } from "../Widget";
import * as Ant from "antd";
import * as Icon from "../Icon";
import * as Survey from "../../Contexts/SurveyContext";

export default function AddTemplateToPublicModal(props) {
  const {
    visible,
    setVisible,
    setSaveToTemplateData,
    surveyId,
    setSaveToTemplateTitle,
    saveToTemplateTitle,
  } = props;
  const [surveyList, setSurveyList] = useState([]);
  const [templateCategoryList, setTemplateCategoryList] = useState([]);
  const [templateCategory, setTemplateCategory] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSurveyList();
    fetchTemplateCategory();
  }, []);

  async function fetchSurveyList() {
    let list = await Survey.Actions.fetchMySurveys();
    // console.log("list", list);
    setSurveyList(list);
  }

  // 取得範本類別列表
  async function fetchTemplateCategory() {
    const response = await Survey.Actions.fetchTemplateCategory();
    if (
      response.code == "200" &&
      response.data &&
      Array.isArray(response.data)
    ) {
      setTemplateCategoryList(response.data);
    }
  }

  async function onOk() {
    // 2022-09-23 新增未選擇類別、正在處理中的判斷
    if (!saveToTemplateTitle || !templateCategory || loading) {
      return;
    }

    // 2022-09-23 新增Loading動畫
    setLoading(true);

    const result = await Survey.Actions.saveToPublicTemplate(
      surveyId,
      saveToTemplateTitle,
      templateCategory
    );
    // 2022-09-01 ALVIN 增加新增常用範本API判斷檢查
    const resultData = JSON.parse(result);
    console.log("result", result);
    if (resultData.code !== "200") {
      Ant.message.error(resultData.message);
      setVisible(false);
      setSaveToTemplateData(null);
      setSaveToTemplateTitle("");
      setLoading(false);
      Ant.Modal.destroyAll();
      return;
    }
    // navigate(`/survey?id=${survey.id}`);
    Ant.message.success("已另存成共用範本");
    setVisible(false);
    setSaveToTemplateData(null);
    setSaveToTemplateTitle("");
    setLoading(false);
    Ant.Modal.destroyAll();
  }

  async function onCancel() {
    setVisible(false);
    setSaveToTemplateData(null);
    setSaveToTemplateTitle("");
    reset();
  }

  const reset = () => {
    setTemplateCategory(undefined);
  };

  return (
    <Ant.Modal
      className="custom-modal"
      title="另存成常用範本"
      visible={visible}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onCancel()}
      onOk={() => onOk()}
      okText="確定"
      cancelText="取消"
      width={600}
      centered
      confirmLoading={loading}
    >
      <NoticeWrapper>
        <Icon.InfoCircleOutlined
          size={FontSize.title}
          color={Color.GreyWhite}
        />
        <Font.Body style={{ color: Color.GreyWhite }}>
          此為管理員功能，存為常用範本後，僅能編輯名稱、重新分類問卷類別與刪除，不能編輯此問卷範本內容。
        </Font.Body>
      </NoticeWrapper>
      <Widget.FlexCol style={{ padding: "32px 36px" }}>
        <Font.Body style={{ marginBottom: 8 }}>問卷名稱</Font.Body>
        {/* <Ant.Select value={surveyId} disabled>
          {surveyList.map((itm, idx) => (
            <Ant.Select.Option value={itm.id} key={idx}>
              {itm.title}
            </Ant.Select.Option>
          ))}
        </Ant.Select> */}
        <Ant.Input
          style={{ marginBottom: "24px" }}
          value={saveToTemplateTitle}
          onChange={e => setSaveToTemplateTitle(e.target.value)}
        />
        <Font.Body style={{ marginBottom: 8 }}>範本類別</Font.Body>
        <Ant.Select
          style={{ width: "200px" }}
          placeholder="範本類別"
          value={templateCategory}
          onChange={e => setTemplateCategory(e)}
        >
          {templateCategoryList.map((itm, idx) => (
            <Ant.Select.Option value={itm.Code} key={idx}>
              {itm.Name}
            </Ant.Select.Option>
          ))}
        </Ant.Select>
      </Widget.FlexCol>
    </Ant.Modal>
  );
}

const NoticeWrapper = styled.div`
  padding: 12px 40px 12px 32px;
  display: flex;
  align-items: flex-start;
  background-color: ${Color.GreyBlack};

  > span[role="img"] {
    margin: 3px 12px 0 0;
  }
`;
