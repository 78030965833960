export default {
  typeface: "Noto Sans",
  typefaceArticle: "",

  sizeHead: 16,
  sizeBody: 14,

  sizeArtiHead1: 38,
  sizeArtiHead2: 30,
  sizeArtiBody: 20,

  colorDark: "#262626",
  colorDarkGrey: "#595959",
  colorGrey: "#8C8C8C",
  colorLightGrey: "#BFBFBF",
  colorLink: "#1890FF",
  colorBackground: "#F0F3F7",

  radiusButton: 4, //px
};
