import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import _ from "lodash";
import styled from "styled-components";
import * as Ant from "antd";
import { search as fuzzySearch, fuzzy } from "fast-fuzzy";

import AddTemplateToPublicModal from "../Components/MyTemplates/AddTemplateToPublicModal";
import SearchInput from "../Components/SearchInput";

import Widget, { Font, Color, FontSize } from "./Widget";
import * as Survey from "../Contexts/SurveyContext";
import * as Icon from "./Icon";
import { dateFormat } from "../Utils/DateUtil";

const FilterDropdown = styled.div`
  width: 180px;
  padding: 12px 6px 12px 12px;
  .filter-scroll {
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    /* margin-bottom: 12px; */
  }
`;
// 下拉選單元件
function FilterDropdownComponent(props) {
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState([]);
  const {
    reset = false,
    filters = [],
    selectedKeys = [],
    setSelectedKeys = () => {},
    onChange = () => {},
    confirm = () => {},
    clearFilters = () => {},
    visible,
  } = props;

  useEffect(() => {
    if (visible) {
      setSelected(selectedKeys);
    } else {
      setSearchValue("");
      setSelected([]);
    }
  }, [visible]);

  useEffect(() => {
    if (reset) {
      console.log("feng", reset);
      setSearchValue("");
      setSelected([]);
      setSelectedKeys([]);
      confirm();
    }
  }, [reset]);

  function handleChange(obj) {
    if (selected.includes(obj.value)) {
      const temp = selected.filter(v => v !== obj.value);
      setSelected(temp);
    } else {
      const temp = [...selected, obj.value];
      setSelected(temp);
    }
  }

  function onComfirm() {
    onChange(selected);
    setSelectedKeys(selected);
    confirm();
  }

  const options = searchValue
    ? fuzzySearch(searchValue, filters, {
        keySelector: obj => obj.text,
      })
    : filters;

  return (
    <FilterDropdown>
      <Ant.Input
        placeholder="請輸入"
        size="small"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        style={{ width: "100%" }}
      />
      <Ant.Divider style={{ margin: "12px 0" }} />
      <div className="filter-scroll scrollbar-small">
        {options.length ? (
          <Ant.Space direction="vertical">
            {options.map(o => {
              return (
                <Ant.Checkbox
                  checked={selected.includes(o.value)}
                  onChange={() => handleChange(o)}
                >
                  {o.text}
                </Ant.Checkbox>
              );
            })}
          </Ant.Space>
        ) : (
          <Ant.Empty image={Ant.Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      {/* <hr /> */}
      <Ant.Divider style={{ margin: "12px 0" }} />
      <Ant.Space style={{ justifyContent: "space-between", width: "100%" }}>
        <Ant.Button type="text" onClick={clearFilters} size="small">
          重置
        </Ant.Button>
        <Ant.Button type="primary" onClick={onComfirm} size="small">
          套用
        </Ant.Button>
      </Ant.Space>
    </FilterDropdown>
  );
}
// 問卷清單
function SurveyList({ loading, ...props }) {
  const { showSearch = false } = props;
  const { searchName = "" } = props;
  const { surveyStatusList } = props;
  const { pageTitle } = props;
  const {
    pageInfo = { current: 1, total: 0 },
    perPage = 10,
    reset = false,
    onPageChange = () => {},
    onTableChange = () => {},
    onSearch = () => {},
    filters = {
      userName: [],
    },
  } = props;
  const [filter, setFilter] = useState("");
  const [popoverVisibleIdx, setPopoverVisibleIdx] = useState(null);
  const [saveToTemplateData, setSaveToTemplateData] = useState(null);
  const [saveToTemplateTitle, setSaveToTemplateTitle] = useState("");
  const [sendCopyData, setSendCopyData] = useState(null);
  const [sendCopyToUser, setSendCopyToUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [
    showAddTemplateToPublicModal,
    setShowAddTemplateToPublicModal,
  ] = useState(false);
  const [okValid, setOkValid] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [saveToPublicData, setSaveToPublicData] = useState(null);
  // 傳送副本Loading
  const [sendCopyLoading, setSendCopyLoading] = useState(false);

  const totalSurveys = props.surveys || [];
  var surveys = totalSurveys;
  // var surveys = filter
  //   ? totalSurveys.filter(obj => {
  //       const res =
  //         fuzzy(filter, obj.title || "") > 0.9 ||
  //         fuzzy(filter, obj.author || "") > 0.9;
  //       console.log(obj, res);
  //       return res;
  //     })
  //   : totalSurveys;

  if (searchName !== "") {
    surveys = totalSurveys.filter(obj => {
      if (obj.title.includes(searchName)) {
        return true;
      }
    });
  }
  // 使用者過濾清單，用於搜尋
  const userFilterList = _.uniq(_.get(filters, "userName", [])).map(name => ({
    text: name,
    value: name,
  }));
  // 狀態清單，用於搜尋
  const statusFilterList = surveyStatusList.map(obj => ({
    text: obj.description,
    value: obj.status,
  }));

  useEffect(() => {
    // 拉用戶資料
    async function fetchUserList() {
      try {
        let users = await Survey.Actions.fetchAccountListNonAdmin();
        setUserList(users);
      } catch (err) {
        //
        setUserList([]);
      }
    }

    if (sendCopyData) {
      fetchUserList();
    } else {
      //
    }
  }, [sendCopyData]);
  console.log(props);
  const columns = [
    {
      title: "編號",
      key: "sn",
      dataIndex: "sn",
      width: 57,
      align: "left",
      fixed: "left",
      render: (_, record, idx) => idx + 1,
    },
    {
      title: "標題",
      key: "title",
      width: 312,
      render: (_, record) => (
        <>
          <Font.Body style={{ color: Color.Black_85 }}>
            {record.title}
          </Font.Body>
          <Font.Small style={{ color: Color.LightBlue }}>
            {`有效期間: ${Survey.Utils.getValidPeriod(record)}`}
          </Font.Small>
        </>
      ),
    },
    {
      title: "狀態",
      key: "status",
      width: 129,
      filterDropdown: props => (
        <FilterDropdownComponent
          {...props}
          reset={reset}
          onChange={list => {
            console.log(list);
          }}
        />
      ),
      filters: statusFilterList,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      // onFilter: (value, record) => {
      //   return record?.status === value;
      // },
      render: (_, record) => {
        const [text, color] = Survey.Utils.getStatusLabel(
          record,
          surveyStatusList
        );
        return (
          <StatusLabel style={{ backgroundColor: color }}>
            <Font.Small style={{ color: Color.GreyBlack }}>
              {text || "-"}
            </Font.Small>
          </StatusLabel>
        );
      },
    },
    {
      title: "回覆數",
      key: "responseCount",
      width: 126,
      align: "right",

      render: (_, { responseCount }) =>
        responseCount === null ? "-" : responseCount,
    },
    {
      title: "負責人",
      key: "author",
      width: 126,
      align: "left",
      filterDropdown: props => (
        <FilterDropdownComponent
          {...props}
          reset={reset}
          onChange={list => {
            console.log(list);
          }}
        />
      ),
      filters: userFilterList,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      // onFilter: (value, record) => {
      //   return record?.author === value;
      // },
      render: (_, { author }) => <Font.Body>{author}</Font.Body>,
    },
    {
      title: "最後修改時間",
      width: 140,
      key: "modified",
      render: (_, { modified }) => (
        <div style={{ color: Color.Black_45 }}>
          {dateFormat(modified) || "-"}
        </div>
      ),
    },
    {
      title: () => <span style={{ color: Color.Purple }}>編輯</span>,
      key: "edit",
      width: 60,
      align: "center",
      render: (_, record) => (
        <ClearButton
          type="text"
          onClick={() => {
            // if (record.audit) {
            //   // note: audit = true 已陳核，不可編輯
            //   return;
            // }
            if (record && record.isBlank) {
              // note: 問卷為空，選擇新增或匯入範本
              props.openShowCreateModal(record);
            } else {
              navigate(`/survey?id=${record.id}`);
            }
          }}
          icon={<Icon.Edit color={Color.Purple} css="width: 20px;" />}
        ></ClearButton>
      ),
    },
    {
      title: () => <span style={{ color: Color.Purple }}>分析</span>,
      key: "analyze",
      width: 60,
      align: "center",
      render: (_, record) => (
        <ClearButton
          type="text"
          onClick={() => {
            navigate(`/survey?id=${record.id}`, { state: { step: 3 } });
          }}
          icon={
            <Icon.BarChart
              // size={FontSize.subTitle}
              color={Color.Purple}
              css="font-size: 20px;"
            />
          }
        ></ClearButton>
      ),
    },
    {
      title: () => <span style={{ color: Color.Purple }}>其他</span>,
      key: "more",
      width: 60,
      align: "center",
      render: (_, record, idx) => (
        <Ant.Popover
          placement="bottomLeft"
          visible={popoverVisibleIdx === idx}
          onVisibleChange={visible =>
            setPopoverVisibleIdx(visible ? idx : null)
          }
          content={
            <div style={{ minWidth: 122, margin: "-8px -16px" }}>
              <Font.Body
                style={{
                  color: !record.isBlank ? Color.GreyBlack : Color.Gray_10,
                  cursor: "pointer",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                className="hover-mask"
                onClick={() => {
                  if (!record.isBlank) {
                    setPopoverVisibleIdx(null);
                    setSaveToTemplateData(record);
                    setSaveToTemplateTitle(record.title);
                  }
                }}
              >
                另存至我的範本
              </Font.Body>
              <Font.Body
                style={{
                  color: !record.isBlank ? Color.GreyBlack : Color.Gray_10,
                  cursor: "pointer",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                className="hover-mask"
                onClick={() => {
                  if (!record.isBlank) {
                    setPopoverVisibleIdx(null);
                    setSendCopyData(record);
                  }
                }}
              >
                傳送副本
              </Font.Body>
              {props.profile?.RoleId === 1 ? (
                <Font.Body
                  style={{
                    color: !record.isBlank ? Color.GreyBlack : Color.Gray_10,
                    cursor: "pointer",
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                  className="hover-mask"
                  disabled={record.isBlank}
                  onClick={() => {
                    if (!record.isBlank) {
                      setPopoverVisibleIdx(null);
                      setSaveToPublicData(record);
                      setSaveToTemplateTitle(record.title);
                      setShowAddTemplateToPublicModal(true);
                    }
                  }}
                >
                  另存成常用範本
                </Font.Body>
              ) : null}
            </div>
          }
          trigger="click"
        >
          <ClearButton
            type="text"
            icon={<Icon.Ellipsis color={Color.Purple} css="font-size: 20px;" />}
          ></ClearButton>
        </Ant.Popover>
      ),
    },
  ];
  let adds = {};
  if (reset) {
    adds.value = "";
  }

  return (
    <>
      {surveyStatusList && (
        <Wrapper>
          <div className="title-wrapper">
            <Font.LargeTitle>{pageTitle}</Font.LargeTitle>
            {!showSearch && (
              <>
                {/* <Ant.Select
                value={filter}
                onChange={setFilter}
                style={{ width: 162 }}
              >
                <Ant.Select.Option value={""}>全部狀態</Ant.Select.Option>
                {surveyStatusList
                  .filter(option => ["6", "7"].indexOf(option.status) === -1)
                  .map((item, idx) => (
                    <Ant.Select.Option key={idx} value={item.status}>
                      {item.description}
                    </Ant.Select.Option>
                  ))}
              </Ant.Select> */}
                <SearchInput
                  placeholder="問卷名稱、負責人..."
                  onChange={e => {
                    onSearch(e.target.value);
                    // setFilter(e.target.value)
                  }}
                  {...adds}
                />
              </>
            )}
          </div>
          <Ant.Table
            dataSource={surveys}
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={{
              showSizeChanger: false,
              current: pageInfo.current,
              total: pageInfo.total,
              pageSize: perPage,
              onChange: p => {
                onPageChange(p);
              },
            }}
            onChange={(selectedRowKeys, selectedRows, info) => {
              onTableChange(selectedRowKeys, selectedRows, info);
            }}
          />
          <Ant.Modal
            visible={!!saveToTemplateData}
            title="另存至自訂範本"
            bodyStyle={{ padding: 0, margin: 0 }}
            onCancel={() => {
              setSaveToTemplateData(null);
              setSaveToTemplateTitle("");
            }}
            okText={"確定"}
            onOk={async () => {
              if (!saveToTemplateTitle) {
                return;
              }
              setOkValid(true);
              await Survey.Actions.saveToMyTemplate(
                saveToTemplateData.id,
                saveToTemplateTitle
              );
              setSaveToTemplateData(null);
              setSaveToTemplateTitle("");
              setOkValid(false);
            }}
            okButtonProps={{
              disabled: okValid,
            }}
            cancelText={"取消"}
          >
            <div style={{ padding: "34px 32px 38px 32px" }}>
              <Ant.Input
                value={saveToTemplateTitle}
                onChange={e => setSaveToTemplateTitle(e.target.value)}
              />
            </div>
          </Ant.Modal>
          <Ant.Modal
            visible={!!sendCopyData}
            title="傳送複本"
            bodyStyle={{ padding: 0, margin: 0 }}
            onCancel={() => {
              setSendCopyData(null);
              setSendCopyToUser(null);
            }}
            okText={"確定"}
            cancelText={"取消"}
            confirmLoading={sendCopyLoading}
            onOk={async () => {
              if (!sendCopyToUser) {
                return;
              }
              setSendCopyLoading(true);
              await Survey.Actions.sendCopyToUser(
                sendCopyData.id,
                sendCopyToUser
              );
              setSendCopyData(null);
              setSendCopyToUser(null);
              setSendCopyLoading(false);
            }}
          >
            <div>
              <Widget.FlexRow
                style={{
                  marginBottom: 14,
                  marginTop: 16,
                  paddingLeft: 32,
                  paddingRight: 18,
                }}
              >
                <Font.Head style={{ color: Color.GreyBlack }}>將</Font.Head>
                <Font.Head style={{ color: Color.Purple, marginLeft: 16 }}>
                  {sendCopyData?.title || ""}
                </Font.Head>
              </Widget.FlexRow>
              <Widget.FlexRow
                style={{ marginBottom: 26, paddingLeft: 32, paddingRight: 18 }}
              >
                <Font.Head style={{ color: Color.GreyBlack }}>
                  傳送一份複本給
                </Font.Head>
                <Ant.Select
                  style={{ marginLeft: 16, flex: 1 }}
                  value={sendCopyToUser}
                  placeholder={"選擇使用者"}
                  onChange={v => {
                    setSendCopyToUser(v);
                  }}
                >
                  {userList.map((u, idx) => (
                    <Ant.Select.Option key={idx} value={u.UserId}>
                      {u.name}
                    </Ant.Select.Option>
                  ))}
                </Ant.Select>
              </Widget.FlexRow>
            </div>
          </Ant.Modal>
          <AddTemplateToPublicModal
            visible={showAddTemplateToPublicModal}
            setVisible={setShowAddTemplateToPublicModal}
            setSaveToTemplateTitle={setSaveToTemplateTitle}
            saveToTemplateTitle={saveToTemplateTitle}
            setSaveToTemplateData={setSaveToTemplateData}
            surveyId={saveToPublicData?.id}
          />
          <Widget.CopyrightFooter />
        </Wrapper>
      )}
    </>
  );
}

const ClearButton = styled(Ant.Button)`
  &,
  &:hover {
    border: none;
    box-shadow: none;
    background-color: transparent;
    /* outline: none; */
  }
`;

const Wrapper = styled.div`
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    height: 40px;
  }

  .ant-table-pagination.ant-pagination {
    padding-top: 24px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.15);
    a {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.25s linear;
    }
    &.ant-pagination-item-active a {
      background-color: #534ab3;
      color: #ffffff;
    }
  }

  .ant-table {
    th {
      line-height: 1.57;
      padding: 4px 13px;
      border-bottom: 1px solid transparent;
      background-color: #fafafa;
      font-weight: 900;
      color: ${Color.Black_65};
      .ant-table-filter-column-title {
        color: ${Color.Black_65};
      }
    }
    td {
      padding: 6px 13px;
      line-height: 1.57;
      color: ${Color.Black_85};
    }
    th {
      .ant-table-filter-column {
        display: flex;
        margin: 0;
        width: 100%;
        align-items: center;
        .ant-table-filter-column-title,
        .ant-table-filter-trigger-container {
          padding: 0;
          flex: 0 auto;
          display: block;
          position: relative;
        }

        .ant-table-filter-trigger-container {
          margin-left: 7px;
          .ant-table-filter-trigger {
            width: 100%;
          }
        }
      }
    }

    td {
      padding: 6px 13px;
    }
  }
`;

const StatusLabel = styled.div`
  display: inline-block;
  border-radius: 18.5px;
  padding: 0px 11px;
  font-size: 13px;
  line-height: 22px;
  height: 22px;
`;

export default SurveyList;
